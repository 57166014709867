<template>
    <div class="container">
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 }}
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import { formateMoment } from "@/utils/common";
import { apiAccessLog } from "@/api";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "访问时间",
        dataIndex: "createdTime",
    },
    {
        title: "用户名",
        dataIndex: "username",
    },
    {
        title: "访问页面",
        dataIndex: "accessPage",
    },
];
export default defineComponent({
    components: {
        FilterBlock,
    },
    setup() {
        const state = reactive({
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiAccessLog({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    exception: true,
                },
                ...params,
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        getTabelData();

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            getTabelData,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
